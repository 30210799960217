<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the consent question details</h4>
      <form autocomplete="off">
        <vs-row vs-w="6">
          <vs-col>
            <div class="w-full m-5">
              <label class="label" name="question-label">Question</label>
              <vs-textarea
                :success="
                  !errors.first('basic.question') &&
                  questionnaire.question != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.question') ? true : false"
                v-validate="'required'"
                name="question"
                data-vv-as="Question"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.question')"
                v-model="questionnaire.question"
              />
            </div>
            <span
              style="margin-left: 20px"
              class="text-danger text-sm"
              v-if="errors.first('basic.question')"
              >Please compose a question</span
            >
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <label class="label" name="treatment-type">Treatment type</label>
              <v-select
                multiple
                :closeOnSelect="false"
                label="name"
                :options="treatments"
                v-model="selectedTreatment"
                name="treatmentId"
                class="w-full"
              />
            </div>
            <span
              style="margin-left: 20px"
              class="text-danger text-sm"
              v-if="errors.first('basic.question')"
              >Please choose a treatment type</span
            >
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <label class="label" name="treatment-type"
                >Is this question mandatory?</label
              ><br />
              <vs-radio
                vs-value="true"
                style="margin-right: 20px"
                v-model="questionnaire.is_mandatory"
                >Yes</vs-radio
              >
              <vs-radio vs-value="false" v-model="questionnaire.is_mandatory"
                >No</vs-radio
              >
            </div>
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <label class="label" name="treatment-type"
                >Status
                {{ questionnaire.status ? "Enabled" : "Disabled" }}</label
              ><br />
              <vs-switch
                vs-value="true"
                style="margin-right: 20px"
                v-model="questionnaire.status"
                >Yes</vs-switch
              >
            </div>
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveData"
                icon-pack="feather"
                class="mr-2"
                >Save</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import clinicViewEditDetail from "../../components/clinics/viewEditDetail.vue";
import { mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  name: "questionnaireCreate",
  props: {
    backRoute: {
      type: String,
      default: "ConsentQuestionnaire",
    },
  },
  components: {
    "v-select": vSelect,
  },
  data: () => ({
    questionnaire: {
      question: "",
      status: true,
      is_mandatory: "true",
    },
    treatments: [
      {
        _id: "",
        isAvailableToAll: "",
        name: "",
        description: "",
      },
    ],
    clinicId: "",
    selectedTreatment: [],
    order: 0,
    isEditingMode: false,
  }),
  methods: {
    ...mapActions("questionnaire", [
      "createCustomConsent",
      "getCustomConsent",
      "fetchTreatmentPlans",
    ]),
    ...mapActions("clinic", ["changeCustomConsentOrders"]),
    getTreatmentPlans() {
      this.fetchTreatmentPlans().then((res) => {
        this.treatments = res.data.data;
      });
    },
    async saveData() {
      if (!this.isEditingMode) this.createQuestion();
      else this.updateQuestion();
    },
    async updateQuestion() {
      let self = this;
      let isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate();

      let treatmentIds = [];
      this.selectedTreatment.forEach(function (item) {
        if (item._id) {
          treatmentIds.push({
            treatmentId: item._id,
            treatmentName: item.name,
          });
        }
      });
      if (isValid) {
        const item = {
          ...this.$route.params.editData,
          question: this.questionnaire.question,
          treatments: treatmentIds,
          is_mandatory:
            this.questionnaire.is_mandatory === "true" ? true : false,
          status: this.questionnaire.status,
        };
        this.changeCustomConsentOrders({ ...item })
          .then((res) => {
            self.$vs.notify({
              title: "Question Updated",
              text: "Question updated Successfully.",
              color: "success",
            });
            console.log("res", res);
            self.$router.push("/clinic/custom-consent");
          })
          .catch((err) => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to update questionnaire. Try again!",
              color: "danger",
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
          });
      }
    },
    async createQuestion() {
      let self = this;
      let isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate();

      if (isValid) {
        const { id: clinicId } = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        );
        await this.getCustomConsent(clinicId).then((res) => {
          let questions = res.data.data;
          if (questions.length > 0) {
            questions = questions.sort((a, b) => b.order - a.order);
            this.order = questions[0].order + 1;
          } else {
            this.order = 1;
          }
        });
        let treatmentIds = [];
        this.selectedTreatment.forEach(function (item) {
          if (item._id) {
            treatmentIds.push({
              treatmentId: item._id,
              treatmentName: item.name,
            });
          }
        });

        const { id } = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        );
        this.questionnaire.treatmentId = treatmentIds;
        let info = this.questionnaire;
        info.is_mandatory = info.is_mandatory === "true" ? true : false;
        info.clinicId = id;
        info.order = this.order;
        let payload = {
          ...this.questionnaire,
          treatments: treatmentIds,
        };
        await this.createCustomConsent(payload)
          .then((res) => {
            self.$vs.notify({
              title: "Question Created",
              text: "Question created Successfully.",
              color: "success",
            });
            console.log("res", res);
            self.$router.push("/clinic/custom-consent");
          })
          .catch((err) => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to created questionnaire. Try again!",
              color: "danger",
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
  },
  mounted() {},
  created() {
    if (this.$route.params.editData !== undefined) {
      const editData = this.$route.params.editData;
      this.isEditingMode = true;
      this.questionnaire.question = editData.question;
      this.questionnaire.status = editData.status;
      this.questionnaire.is_mandatory = "" + editData.is_mandatory;
      this.order = editData.order;
      this.selectedTreatment = editData.treatments.map((item) => {
        return {
          name: item.treatmentName,
          _id: item._id,
        };
      });
    }
    this.getTreatmentPlans();
  },
};
</script>

